<template>
  <div>
    <div class="label">基础信息</div>
    <el-form label-width="120px">
      <el-form-item label="分类" required>
        <el-select v-model="params.classifyId2" placeholder="请选择">
          <el-option
            :label="item.name"
            :value="item.id"
            :key="item.id"
            v-for="item in labelList"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="命名" required>
        <el-input v-model="params.name"></el-input>
      </el-form-item>
      <el-form-item label="封面" required>
        <el-input v-model="params.coverImage"></el-input>
      </el-form-item>
      <el-form-item label="基础信息">
        <el-input
          v-model="params.basicInfo"
          type="textarea"
          placeholder="百度搜索信息填写，什么科什么的。"
          :rows="5"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="label">模型信息</div>
    <div class="label">比赛使用建议</div>
    <el-form label-width="120px">
      <el-form-item label="技术使用建议">
        <el-input
          v-model="params.technicalSuggestion"
          :rows="3"
          type="textarea"
          placeholder="玫瑰可以使用铜丝压弯如不小心剪断后可以用铁丝固定"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="save(1)">新增并发布</el-button>
    <el-button type="primary" @click="save(2)">存储待发</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        classifyId2: "",
        name: "",
        coverImage: "",
        basicInfo: "",
        technicalSuggestion: "",
        detail: "",
      },
      labelList: [],
    };
  },
  created() {
    this.getLabelList();
  },
  methods: {
    getLabelList() {
      this.$ajax.post("/admin/classifyManage/queryAll").then((res) => {
        this.labelList = res.data.find((item) => item.id === 3).child;
      });
    },
    save(status) {
      this.$ajax
        .post("admin/materialManage/save", {
          ...this.params,
          classifyId1: 3,
          status,
        })
        .then((res) => {
          this.$message.success("保存成功");
          this.$router.push("/jianshan/list");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 20px;
  color: #999;
  margin: 15px 0;
}
</style>